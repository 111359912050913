import React, { useEffect, useState } from "react";
import "./Notification.css";
import UserNavPage from "../UserNavBar/userNav";
import { useLocation } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../BaseUrl";
import { BsThreeDots } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function NotificationPage() {
  const location = useLocation();
  const useremail = location.state[0];
  const loginUserData = location.state[1];

  const nevigate = useNavigate();
  const recieverRefrenceId = loginUserData[0]?.refrenceId;

  console.log(">>>>>>>>>>", recieverRefrenceId);
  const [likesNotification, setLikeNotification] = useState([]);

  // Its api for find the like notification
  const getLikeStatusRequest = () => {
    console.log(recieverRefrenceId);
    axios
      .get(`${baseUrl}/api/recievergetlikestatus/${recieverRefrenceId}`)
      .then((res) => {
        const { success, message } = res.data;
        if (success === true) {
          setLikeNotification(message);
        } else {
          setLikeNotification(message);
        }
      });
  };

  useEffect(() => {
    getLikeStatusRequest();
  }, []);

  console.log("likenotui", likesNotification);

  const handleLikeDelete = (e) => {
    const id = e.target.getAttribute("id");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${baseUrl}/api/deletelikeStatus/${id}`).then((res) => {
          const { message, success } = res.data;
          if (success) {
            Swal.fire({
              title: message,
              text: "You clicked the button!",
              icon: "success",
            });
          } else {
            alert(message);
          }
        });
        window.location.reload();
      }
    });
  };

  const SeenLikePrfileClick = (row) => {
    const id = row._id;
    console.log(row.sender.email);

    axios.put(`${baseUrl}/api/seenlikeprofileupdate/${id}`).then((res) => {
      const { message, success } = res.data;
      if (success) {
        nevigate("/profile", {
          state: [row.sender.email, useremail, loginUserData],
        });
      } else {
      }
    });
  };
  const formatTime = (timestamp) => {
    const currentDate = new Date();
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayDiff = (currentDate - date) / (1000 * 60 * 60 * 24);

    if (dayDiff < 1) {
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
    } else if (dayDiff >= 1 && dayDiff < 2) {
      return "Yesterday";
    }
  };

  return (
    <>
      <UserNavPage useremail={useremail} loginUserData={loginUserData} />
      <div class="notifiContainer container">
        <div class="row">
          <div class="col"></div>
          <div class="col-md-7">
            {likesNotification.length === 0 ? (
              <div class=" card">
                <div class="card-body">
                  <div class="card">
                    <div class="nofriendRequest card-body">
                      <p>You don't have any Notification!</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="mainNotificationCard card">
                {/* <div class="card-body"> */}
                {likesNotification
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  ?.map((row) => {
                    return (
                      <div key={row._id} class="mainNotifyCard card">
                        <div
                          class={`notificationCard card-body ${
                            !row.isSeen ? "unseenNotification" : ""
                          }`}
                        >
                          <div class="container">
                            <div class="row">
                              <div
                                class="col-md-6"
                                onClick={() => SeenLikePrfileClick(row)}
                              >
                                <div class="container">
                                  <div class="row">
                                    <div class="col-md-3 col-sm-3 col-xs-3">
                                      {row.sender.photo === undefined ? (
                                        <div class="senderprofileStyle">NA</div>
                                      ) : (
                                        <img
                                          className="senderProfile"
                                          src={`${baseUrl}/uploads/${row.sender.photo}`}
                                        ></img>
                                      )}
                                    </div>
                                    <div class="col">
                                      <p className="RequesterName mb-0">
                                        {row.sender.username}
                                      </p>
                                      <p>Likes Your Profile</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="container">
                                  <div class="row">
                                    <div class="col"></div>
                                    <div class="col">
                                      <button
                                        className="removeConnection btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenu2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <BsThreeDots className="threeDotsIcon" />
                                      </button>
                                      <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenu2"
                                      >
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            type="button"
                                            id={row._id}
                                            onClick={handleLikeDelete}
                                          >
                                            <RiDeleteBin6Line />
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <p className="timestamp">
                                  {formatTime(row.timestamp)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* </div> */}
              </div>
            )}
          </div>
          <div class="col"></div>
        </div>
      </div>
    </>
  );
}
