import React, { useState, useEffect } from "react";
import "./Profiles.css";
import UserNavPage from "../UserNavBar/userNav";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FooterPage from "../../LandingPage/Footer/Footer";
import { BsPersonBoundingBox } from "react-icons/bs";
import Swal from "sweetalert2";
import baseUrl from "../../../BaseUrl";
import io from "socket.io-client";
export const socket = io.connect(`${baseUrl}`);

export default function ProfilePage() {
  const location = useLocation();
  const nevigateData = location.state;
  const email = nevigateData[0];
  const useremail = nevigateData[1];
  const loginUserData = nevigateData[2];
  const [recieverUserData, setReciverUserData] = useState([{}]);
  const [allPhoto, setAllPhoto] = useState([]);
  const [message, setMessage] = useState("");
  const [isFriendRequestSent, setIsFriendRequestSent] = useState(false);
  const [senderStatus, setSenderStatus] = useState("");
  const [senderLikeStatus, setSenderLikeStatus] = useState("");
  const [isLikeProfile, setIsLikeProfile] = useState(false);
  const [recieverReqStatus, setRecieverReqStatus] = useState();
  const [messageRoom, setMessageRoom] = useState("");

  const getRecieverUserDataApi = () => {
    axios.get(`${baseUrl}/api/getuserdatabyemail/${email}`).then((res) => {
      setReciverUserData(res.data);
    });
  };

  const refrenceId = recieverUserData[0]?.refrenceId;
  const receiverId = recieverUserData[0]?.refrenceId;
  const managePhotoClick = () => {
    axios.get(`${baseUrl}/api/getphotorefrenceid/${refrenceId}`).then((res) => {
      const { message, success, data } = res.data;
      if (success === true) {
        setAllPhoto(data);
      } else {
      }
    });
  };

  const senderId = loginUserData[0]?.refrenceId;

  const friendrequestsenderstatusApi = () => {
    axios
      .post(
        `${baseUrl}/api/friendrequestsenderstatus/${senderId}/${receiverId}`
      )
      .then((res) => {
        const { message, success } = res.data;
        if (success === true) {
          setIsFriendRequestSent(true);
          setSenderStatus(message);
        } else {
          // setIsFriendRequestSent(false)
          setSenderStatus(message);
        }
      });
  };

  const recieverRefrenceId = loginUserData[0].refrenceId;
  const senderRefrenceId = recieverUserData[0].refrenceId;

  const friendrequestrecieveApi = () => {
    axios
      .get(
        `${baseUrl}/api/getfriendrequestbyboth/${senderRefrenceId}/${recieverRefrenceId}`
      )
      .then((res) => {
        const { message, success } = res.data;
        if (success === true) {
          setRecieverReqStatus(message);
        } else {
        }
      });
  };

  const likeProfilesenderstatusApi = () => {
    axios
      .post(`${baseUrl}/api/likeprofilesenderstatus/${senderId}/${receiverId}`)
      .then((res) => {
        const { message, success } = res.data;
        if (success === true) {
          setIsLikeProfile(true);
          setSenderLikeStatus(message);
        } else {
          setSenderLikeStatus(message);
        }
      });
  };
  const handleUnfriend = (e) => {
    const id = e.target.getAttribute("id");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unfriend it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${baseUrl}/api/unfriend/${id}`).then((res) => {
          const { message, success } = res.data;
          if (success) {
            Swal.fire({
              title: "Removed",
              text: "You clicked the button!",
              icon: "success",
            });
          } else {
            alert(message);
          }
        });
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    // getLoginUserDataApi();
    getRecieverUserDataApi();
  }, []);

  useEffect(() => {
    managePhotoClick();
    friendrequestsenderstatusApi();
    likeProfilesenderstatusApi();
    friendrequestrecieveApi();
  }, [recieverUserData]);

  // <------------Message Send Section ------------------>

  const messageClick = (e) => {
    const recieverRefrenceId = e.target.getAttribute("id");
    setMessageRoom({
      ...messageRoom,
      senderRefrenceId: senderId,
      recieverRefrenceId: recieverRefrenceId,
    });

    if (socket) {
      socket.emit("messagejoin-room", {
        senderRefrenceId: senderId,
        recieverRefrenceId: recieverRefrenceId,
      });
    }
  };

  const messageChange = (e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const messageSend = () => {
    socket.emit("send-message", { message, messageRoom });
    setTimeout(() => {
      socket.emit("send-Ref", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
      socket.emit("send-chstnotify", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
    }, 1000);
    let inputElements = document.querySelectorAll(".chatInput1");
    inputElements.forEach((input) => {
      input.value = "";
    });
  };

  const sendbyrefrenceId = loginUserData[0].refrenceId;
  const friendReuestSend = (e) => {
    const recieverRefrenceId = e.target.getAttribute("id");
    axios
      .post(`${baseUrl}/api/frinedrequest`, {
        recieverRefrenceId,
        sendbyrefrenceId,
      })
      .then((res) => {
        const { message, success } = res.data;
        if (success) {
          setIsFriendRequestSent(true);
          setSenderStatus(message);
        } else {
          setIsFriendRequestSent(false);
        }
      });
  };

  const FriendRequestAccept = (e) => {
    const requestId = e.target.getAttribute("id");
    axios
      .post(`${baseUrl}/api/acceptfriendrequest/${requestId}`)
      .then((res) => {
        const { success, message } = res.data;
        if (success === true) {
          setRecieverReqStatus(message);
        } else {
          alert(message);
        }
      });
  };
  const handleLike = (e) => {
    const recieverRefrenceId = e.target.getAttribute("id");
    axios
      .post(`${baseUrl}/api/likeprofle`, {
        recieverRefrenceId,
        sendbyrefrenceId,
      })
      .then((res) => {
        const { message, success } = res.data;
        if (success) {
          setIsLikeProfile(true);
          setSenderLikeStatus(message);
        } else {
          setIsLikeProfile(false);
        }
      });
  };
  const handleUnLike = (e) => {
    const recieverRefrenceId = e.target.getAttribute("id");
    axios
      .post(`${baseUrl}/api/unlikelikeprofle`, {
        recieverRefrenceId,
        sendbyrefrenceId,
      })
      .then((res) => {
        const { message, success } = res.data;
        if (success) {
          setIsLikeProfile(false);
        } else {
          setIsLikeProfile(false);
        }
      });
  };
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dob.getFullYear();
    const currentMonth = currentDate.getMonth();
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  }

  return (
    <>
      <UserNavPage useremail={useremail} loginUserData={loginUserData} />
      <div class="profileContainer container">
        <div class="profileRow row">
          <div class="col-md-1"></div>
          <div class="col-md-10 mb-5">
            <div class="mainProfileCard card">
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-3">
                      <p className="profileName mb-0">
                        {recieverUserData[0].username}
                      </p>
                      <p className="lastSeenPara">Last Seen online</p>
                      <div class="infoCard card">
                        <div class="cardTitle ">
                          Age: {calculateAge(recieverUserData[0].dob)}
                        </div>
                      </div>
                      <div class="infoCard card mt-1">
                        <div class="cardTitle ">
                          Country : {recieverUserData[0].country}
                        </div>
                      </div>
                      <div class="infoCard card mt-1">
                        <div class="cardTitle ">
                          City : {recieverUserData[0].city}
                        </div>
                      </div>
                      <div class="infoCard card mt-1">
                        <div class="cardTitle ">
                          Gender : {recieverUserData[0].gender}
                        </div>
                      </div>
                      <div class="infoCard card mt-1">
                        <div class="cardTitle ">
                          lookingfor : {recieverUserData[0].lookingfor}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-1"></div>
                          <div class="col">
                            <div class="profileImgCard card">
                              {recieverUserData[0].photo === undefined ? (
                                <div className=" ">
                                  <div class="noProfileCard card-body">
                                    <div></div>
                                    <BsPersonBoundingBox className="profileCol" />
                                  </div>
                                </div>
                              ) : (
                                <div class="card-body">
                                  <img
                                    class="card-img-top"
                                    src={`${baseUrl}/uploads/${recieverUserData[0].photo}`}
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            {recieverReqStatus === undefined ? (
                              <div class="card">
                                {senderStatus ? (
                                  <div>
                                    <button
                                      type="button"
                                      class="requestStatusCard btn btn-secondary"
                                      id={senderStatus._id}
                                      onClick={handleUnfriend}
                                    >
                                      {senderStatus.status}
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      type="button"
                                      class="requestCardBtn btn btn-secondary"
                                      id={recieverUserData[0].refrenceId}
                                      onClick={friendReuestSend}
                                    >
                                      Add Friend
                                    </button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div class="card">
                                {recieverReqStatus[0].status === "pending" ? (
                                  <div>
                                    <button
                                      type="button"
                                      class="requestStatusCard btn btn-secondary"
                                      id={recieverReqStatus[0].id}
                                      onClick={FriendRequestAccept}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      type="button"
                                      class="requestStatusCard btn btn-secondary"
                                      id={recieverReqStatus[0].id}
                                      onClick={handleUnfriend}
                                    >
                                      {recieverReqStatus[0].status}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            <div class="requestCard card">
                              <button
                                type="button"
                                class="messageCardBtn btn btn-secondary"
                                id={recieverUserData[0].refrenceId}
                                data-bs-toggle="modal"
                                onClick={messageClick}
                                data-bs-target="#staticBackdrop"
                              >
                                Message
                              </button>
                            </div>
                            <div class="requestCard card">
                              {isLikeProfile ? (
                                <button
                                  type="button"
                                  class="likeCardBtn btn btn-secondary"
                                  id={recieverUserData[0].refrenceId}
                                  onClick={handleUnLike}
                                >
                                  {senderLikeStatus}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="likeCardBtn btn btn-secondary"
                                  id={recieverUserData[0].refrenceId}
                                  onClick={handleLike}
                                >
                                  Like
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="profilePhotoHed">Photos</h3>
                </div>
                <div className="col">
                  <div class="card">
                    <div class="card-body">
                      <div class="galleryContainer container">
                        <div class="row">
                          {allPhoto.map((row) => {
                            return (
                              <div key={row._id} class="col-md-4">
                                <div class="card mt-3">
                                  <img
                                    className="albumPhoto"
                                    src={`${baseUrl}/uploads/${row.photo}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
      {/* <-------------------------------Message Modal ------------------------------------------> */}

      <div
        className="mt-5 modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="messageModal modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="messageHead modal-title" id="staticBackdropLabel">
                Send a message
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="messagelable form-label"
                >
                  Your Message
                </label>
                <textarea
                  class="chatInput1 form-control"
                  name="message"
                  onChange={messageChange}
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={messageSend}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterPage />
    </>
  );
}
