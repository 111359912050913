import React, { useDebugValue, useEffect, useState } from "react";
import "./userNav.css";
import { BsMessenger } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../BaseUrl";
import { BiLike } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa";
import Swal from "sweetalert2";
import { AiFillHome } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import io from "socket.io-client";
export const socket = io.connect(`${baseUrl}`);

export default function UserNavPage({ useremail, loginUserData }) {
  const nevigate = useNavigate();
  const [photoSection, setPhotoSection] = useState(false);
  const [userData, setUserData] = useState([{}]);
  const [editData, setEditData] = useState("");
  const [resetPassword, setResetPassword] = useState({
    password: "",
    newpassword: "",
    confnewpassword: "",
  });
  const [error, setError] = useState({});
  const [photoData, setPhotoData] = useState();
  const [allPhoto, setAllPhoto] = useState();
  const [getmessage, setGetMessage] = useState();
  const [isOnline, setIsOnline] = useState();
  const [friendRequestNotify, setFriendRequestNotify] = useState([]);
  const [searchData, setSearchData] = useState();
  const [searchFriend, setSearchFriend] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [messageCountNotify, setMessageCountNotify] = useState([]);
  const [likesNotification, setLikeNotification] = useState([]);
  const [recieveAllUserMessages, setRecieveAllUserMessages] = useState([]);

  const getUserDataApi = () => {
    axios.get(`${baseUrl}/api/getuserdata/${useremail}`).then((res) => {
      setUserData(res.data);
    });
  };
  const email = userData[0].email;

  useEffect(() => {
    if (socket) {
      socket.on("user-status", (data) => {
        setIsOnline(data);
      });
      socket.on("recievealluser-message", (data) => {
        setMessageCountNotify((prevData) => [data]);
      });
    }
  }, []);

  useEffect(() => {
    getUserDataApi();
  }, []);

  const recieverRefrenceId = userData[0].refrenceId;
  const getLikeStatusRequest = () => {
    if (recieverRefrenceId) {
      axios
        .get(`${baseUrl}/api/recievergetlikestatus/${recieverRefrenceId}`)
        .then((res) => {
          const { success, message } = res.data;
          if (success === true) {
            setLikeNotification(message);
          } else {
            setLikeNotification(message);
          }
        });
    }
  };
  const getFriendRequest = () => {
    if (recieverRefrenceId) {
      axios
        .get(`${baseUrl}/api/getfriendrequest/${recieverRefrenceId}`)
        .then((res) => {
          const { success, data } = res.data;
          if (success === true) {
            setFriendRequestNotify(data);
          } else {
            alert(data);
          }
        });
    }
  };
  useEffect(() => {
    if (recieverRefrenceId) {
      socket.emit("send-chstnotify", {
        recieverRefrenceId: recieverRefrenceId,
      });
    }
    socket.on("recievealluser-messageNotification", (data) => {
      setRecieveAllUserMessages((prevData) => [data]);
    });
    return () => {
      socket.off("recievealluser-messageNotification");
    };
  }, [userData]);
  const profileLikeNotification = likesNotification.filter(
    (item) => item.isSeen === false
  );
  const FriendRequestNotification = friendRequestNotify.filter(
    (item) => item.isSeen === false
  );

  const getMessageCount = () => {
    let totalMessages = 0;
    recieveAllUserMessages.forEach((data) => {
      const friendMessages = data.messages.filter(
        (item) => item.isSeen === false
      );
      totalMessages += friendMessages.length;
    });
    return totalMessages;
  };
  const refrenceId = userData[0].refrenceId;

  const getmessageApi = () => {
    axios.get(`${baseUrl}/api/getmessage/${refrenceId}`).then((res) => {
      setGetMessage(res.data);
    });
  };
  useEffect(() => {
    getmessageApi();
    getLikeStatusRequest();
    getFriendRequest();
  }, [userData]);

  // <-------------profile and Photo update section------------------>
  const handleProfile = (e) => {
    const id = document.getElementById("id").value;
    axios.get(`${baseUrl}/api/getprofilebyid/${id}`).then((res) => {
      setEditData(res.data);
    });
  };
  const photoUpdate = (e) => {
    const files = e.target.files[0];
    setEditData({ ...editData, photo: files });
  };
  const photoUpdateClick = (e) => {
    const formData = new FormData();
    formData.append("id", editData._id);
    formData.append("photo", editData.photo);
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${baseUrl}/api/updateprofile`, formData).then((res) => {
          const { message, success } = res.data;
          if (success === true) {
            Swal.fire("Saved!", "", "success");
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    });
  };
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };
  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${baseUrl}/api/updateprofile`, editData).then((res) => {
          const { message, success } = res.data;
          if (success === true) {
            Swal.fire("Saved!", "", "success");
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    });
  };
  // <-------------Password Change Section ------------------->
  const passwordClick = () => {
    const id = document.getElementById("idps").value;
    axios.get(`${baseUrl}/api/getprofilebyid/${id}`).then((res) => {
      setResetPassword(res.data);
    });
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setResetPassword({ ...resetPassword, [name]: value });
  };

  const resetPasswordValidate = (value) => {
    let error = {};
    if (!value.password) {
      error.password = "Old password required";
    } else if (!value.newpassword) {
      error.newpassword = "New password required";
    } else if (!value.confnewpassword) {
      error.confnewpassword = "Confirm new password required";
    } else if (value.newpassword !== value.confnewpassword) {
      error.compare = "New password & confirm password not matched";
    } else {
      resetPasswordApi();
    }
    setError(error);
  };
  const resetPassClick = () => {
    resetPasswordValidate(resetPassword);
  };
  const resetPasswordApi = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${baseUrl}/api/resetpassword`, resetPassword).then((res) => {
          const { message, success } = res.data;
          if (success) {
            Swal.fire({
              title: message,
              text: "You clicked the button!",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          }
        });
        // window.location.reload();
      }
    });
  };
  // <---------------Add Photo Section -=>>>>>>>>>>>>>>>>>>>>>>>

  const managePhotoClick = () => {
    const refrenceId = document.getElementById("refrenceid").value;
    axios.get(`${baseUrl}/api/getphotorefrenceid/${refrenceId}`).then((res) => {
      const { message, success, data } = res.data;
      if (success === true) {
        setAllPhoto(data);
        // alert(message)
      } else {
        // alert(message)
      }
    });
  };
  const photoSectionClick = () => {
    setPhotoSection(true);
    const id = document.getElementById("id").value;
    axios.get(`${baseUrl}/api/getprofilebyid/${id}`).then((res) => {
      setPhotoData(res.data);
    });
  };
  const handlePhotoUpload = (e) => {
    const files = e.target.files[0];
    setPhotoData({ ...photoData, photo: files });
  };
  const addPhotoChange = (e) => {
    const { name, value } = e.target;
    setPhotoData({ ...photoData, [name]: value });
  };

  const handlePhotoValidation = (value) => {
    let error = {};

    if (!value.photo) {
      error.photo = "Please upload the photo";
    } else {
      photoAddApi();
    }
    setError(error);
  };
  const photoAddClick = () => {
    handlePhotoValidation(photoData);
  };
  const photoAddApi = () => {
    const formData = new FormData();
    formData.append("refrenceId", photoData.refrenceId);
    formData.append("photo", photoData.photo);
    formData.append("description", photoData.description);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${baseUrl}/api/addphoto`, formData).then((res) => {
          const { message, success } = res.data;
          if (success === true) {
            Swal.fire("Saved!", "", "success");
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    });
  };
  const handlePhotoDelete = (e) => {
    const id = e.target.getAttribute("id");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${baseUrl}/api/deletephoto/${id}`).then((res) => {
          const { message, success } = res.data;
          if (success) {
            Swal.fire({
              title: message,
              text: "You clicked the button!",
              icon: "success",
            });
          } else {
            alert(message);
          }
        });
        window.location.reload();
      }
    });
  };
  const makeProfileClick = (e) => {
    const id = e.target.getAttribute("id");
    Swal.fire({
      title: "Do you want to make profile pic?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get(`${baseUrl}/api/getprofilePhotobyid/${id}`).then((res1) => {
          setTimeout(() => {
            axios
              .put(`${baseUrl}/api/setprofilePhoto`, res1.data)
              .then((res2) => {
                const { message, success, data } = res2.data;
                if (success === true) {
                  Swal.fire("Saved!", "", "success");
                } else if (result.isDenied) {
                  Swal.fire("Changes are not saved", "", "info");
                }
              });
          }, 1000);
        });
      }
    });
  };
  const handleLogOut = (e) => {
    const refrenceId = e.target.getAttribute("id");

    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .post(`${baseUrl}/api/logout`, null, { headers })
      .then((response) => {
        const { message, success } = response.data;
        if (success) {
          socket.emit("logout", refrenceId);
          localStorage.removeItem("token"); // Remove the token from localStorage
          alert(message);
          // Redirect the user to the login or home page
          nevigate("/");
          window.location.reload();
        } else {
          alert(message);
        }
      })
      .catch((error) => {
        console.error("An error occurred during logout:", error);
      });
  };
  // Its for search user code and api

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchData(searchText);
    setShowDropdown(true);
  };
  const getUsers = () => {
    axios
      .get(`${baseUrl}/api/searchuser/${searchData}`)
      .then((res) => {
        const { success, message } = res.data;
        if (success === true) {
          setSearchFriend(message);
        } else {
          setSearchFriend([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching friends:", error);
      });
  };

  useEffect(() => {
    getUsers();
  }, [searchData]);

  const handleDropdownClick = (row) => {
    setSearchData(row.username);

    nevigate("/profile", { state: [row.email, useremail, userData] });
  };
  //  its for receive the like notification count

  const handleNotificationPage = () => {
    axios.post(`${baseUrl}/api/seennotification`).then((res) => {
      const { success, message } = res.data;
      if (success) {
        nevigate("/notification", { state: [useremail, userData] });
      } else alert(message);
    });
  };

  const handleFriendReqPage = () => {
    axios.post(`${baseUrl}/api/seenfriendreqnotification`).then((res) => {
      const { success, message } = res.data;
      if (success) {
        nevigate("/friendrequest", { state: [useremail, userData] });
      } else alert(message);
    });
  };

  const handleChatPage = () => {
    axios.post(`${baseUrl}/api/seenchatnotification`).then((res) => {
      socket.emit("send-Ref", { recieverRefrenceId: recieverRefrenceId });
      socket.emit("send-chstnotify", {
        recieverRefrenceId: recieverRefrenceId,
      });
      const { success, message } = res.data;
      if (success) {
        nevigate("/chat", { state: [useremail, userData] });
      } else alert(message);
    });
  };
  return (
    <>
      <div className="navHeaderStyle">
        <nav class="userNav navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="container">
                    <div class="row">
                      <div className="col">
                        <p class="navbar-brand">Dating Meet</p>
                      </div>
                      <div class="col">
                        <input
                          class="searchForm  form-control me-2"
                          type="search"
                          placeholder="Search"
                          onChange={handleSearch}
                          aria-label="Search"
                        ></input>
                        {showDropdown ? (
                          <div className="searchCard card">
                            <ul className="card-body">
                              {searchFriend.map((row) => (
                                <h6
                                  key={row._id}
                                  className=""
                                  onClick={() => handleDropdownClick(row)}
                                >
                                  {row.username}
                                </h6>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="row">
                    <div className="col">
                      <button
                        type=" button"
                        class="notificationIconBtn btn btn-primary position-relative"
                        onClick={() => {
                          nevigate("/userpannel", { state: useremail });
                        }}
                      >
                        <AiFillHome className="notificationIcon" />
                        {/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">1</span> */}
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type=" button"
                        class="notificationIconBtn btn btn-primary position-relative"
                        onClick={handleFriendReqPage}
                      >
                        <FaUserFriends className="notificationIcon" />
                        {FriendRequestNotification.length === 0 ? (
                          ""
                        ) : (
                          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {FriendRequestNotification.length}
                          </span>
                        )}
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type=" button"
                        class="notificationIconBtn btn btn-primary position-relative"
                        onClick={handleNotificationPage}
                      >
                        <MdNotifications className="notificationIcon" />
                        {profileLikeNotification.length === 0 ? (
                          ""
                        ) : (
                          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {profileLikeNotification.length}
                          </span>
                        )}
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="notificationIconBtn btn btn-primary position-relative"
                        onClick={handleChatPage}
                      >
                        <BsMessenger />
                        {getMessageCount() === 0 ? (
                          ""
                        ) : (
                          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {getMessageCount()}
                          </span>
                        )}
                      </button>
                    </div>
                    <div class="col">
                      {loginUserData[0]?.photo === undefined ? (
                        <>
                          <div
                            class="profileStyle  dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-expanded="false"
                          >
                            NA{" "}
                            <span class="badge rounded-pill bg-success">
                              {userData[0].isOnline}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div
                          class=" dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="#"
                          role="button"
                          aria-expanded="false"
                        >
                          <img
                            className="userProfile"
                            src={`${baseUrl}/uploads/${loginUserData[0].photo}`}
                          ></img>
                          <span class="badge rounded-pill bg-success">
                            {userData[0].isOnline}
                          </span>
                        </div>
                      )}
                      <ul className="ProfileUl dropdown-menu">
                        <li className="profile-list">
                          <button
                            type="button"
                            className="btn"
                            onClick={() =>
                              nevigate("/profile", {
                                state: [email, useremail, userData],
                              })
                            }
                          >
                            Profile
                          </button>
                        </li>
                        <li>
                          <hr class="dropdown-divider" />
                        </li>
                        <li className="profile-list">
                          <button
                            type="button"
                            className="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#modalsetting"
                          >
                            Settings
                          </button>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="profile-list">
                          <button
                            className="btn"
                            id={userData[0].refrenceId}
                            onClick={handleLogOut}
                          >
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* <---------------------------------------------Settings Modal------------------------------------------------------------- */}
      <div
        class="modal fade"
        id="modalsetting"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="settingModal modal-dialog modal-dialog-centered">
          <div class="modal-content mt-5">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Settings
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {/* <---------------------------------Manage Photo Accordian ------------------------------------------> */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  {userData[0]?.refrenceId === undefined ? (
                    ""
                  ) : (
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      value={userData[0].refrenceId}
                      id="refrenceid"
                      onClick={managePhotoClick}
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div class="container">
                        <div class="AccordianRow row">
                          <div class="col"></div>
                          <div class="col">
                            <p className="accordianButton">Manage Photos</p>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    </button>
                  )}
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    {photoSection === false ? (
                      <div class="container">
                        <div class="row">
                          <div class="col"></div>
                          <div class="col"></div>
                          {userData[0]?._id === undefined ? (
                            ""
                          ) : (
                            <div class="addPhotoBtnCol col">
                              <button
                                type="button"
                                class="btn btn-success mt-3"
                                value={userData[0]._id}
                                onClick={photoSectionClick}
                                id="id"
                              >
                                {" "}
                                <strong className="addPhotoBtn">+</strong>Add
                                Photos
                              </button>
                            </div>
                          )}
                        </div>
                        {allPhoto === undefined ? (
                          <div class="col-md-8">
                            <div className="photoInstruction mt-3 mb-5">
                              You have not uploaded any photos yet.
                            </div>
                          </div>
                        ) : (
                          <div class="imageContainer container">
                            <div class="row">
                              <div class="col-md-2"></div>
                              <div class="col-md-8">
                                {allPhoto?.map((row) => {
                                  return (
                                    <>
                                      <div class="photoCard card mb-3 mt-3">
                                        <img
                                          className=" imgStyle"
                                          src={`http://localhost:5000/uploads/${row.photo}`}
                                          alt=""
                                        />
                                      </div>
                                      <div class="container">
                                        <div class="row">
                                          <div class="col">
                                            <div className="">
                                              <BiLike />
                                              <em>0</em>
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div className="">
                                              <FaRegComment />
                                              <em>0</em>
                                            </div>
                                          </div>
                                          <div class="col-md-4 ">
                                            <button
                                              type="button"
                                              class="btn btn-primary btn-sm"
                                              id={row._id}
                                              value={row.photo}
                                              onClick={makeProfileClick}
                                            >
                                              Make profile
                                            </button>
                                          </div>
                                          <div class="col">
                                            <button
                                              type="button"
                                              class="btn btn-danger btn-sm"
                                              id={row._id}
                                              onClick={handlePhotoDelete}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              <div class="col-md-2"></div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <button
                              type="button"
                              className="arrowBtn btn"
                              onClick={() => setPhotoSection(false)}
                            >
                              <BiLeftArrowAlt />{" "}
                            </button>
                          </div>
                          <div class="photoSelect col">
                            <label className="photoSelectLable mb-3">
                              Select your photo to upload
                            </label>
                            <div>
                              <button type="button" class="btn btn-success">
                                <input
                                  type="file"
                                  name="photo"
                                  onChange={handlePhotoUpload}
                                />
                              </button>
                              <p className="error mt-0">{error.photo}</p>
                              <label className="photoSelectLable mt-3 mb-3">
                                Add a text / title to your photos (Optional)
                              </label>
                              <div class="mb-3">
                                <textarea
                                  class="form-control"
                                  name="description"
                                  onChange={addPhotoChange}
                                  placeholder="Write a description to your photo"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                ></textarea>
                              </div>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={photoAddClick}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <----------------------------------------------------------Change Profile Photo Accordian------------------------------------------------> */}
              <div class="accordion-item">
                {userData[0]?._id === undefined ? (
                  ""
                ) : (
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      value={userData[0]._id}
                      onClick={handleProfile}
                      id="id"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div class="container">
                        <div class="AccordianRow row mt-3">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <p className="accordianButton">
                              Change Profile Picture
                            </p>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    </button>
                  </h2>
                )}
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="container">
                      <div class="row">
                        <div class="col"></div>
                        <div class="photoSelect col">
                          <label className="photoSelectLable mt-3 mb-3">
                            Select your photo to Change
                          </label>
                          <div>
                            <button type="button" class="btn btn-success">
                              <input
                                type="file"
                                name="photo"
                                onChange={photoUpdate}
                              />
                            </button>
                            <button
                              type="button"
                              className="btn btn-success mt-3"
                              onClick={photoUpdateClick}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div class="col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <---------------------------------------------Edit Your Profile Accordian ----------------------------------> */}
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    value={userData[0]._id}
                    onClick={handleProfile}
                    id="id"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <div class="container">
                      <div class="AccordianRow row mt-3">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                          <p className="accordianButton">Edit Your Profile</p>
                        </div>
                        <div class="col-md-3"></div>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-6 mt-3">
                          {editData.username === null ? (
                            ""
                          ) : (
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput14"
                                class="editLable form-label mb-0"
                              >
                                User Name
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleFormControlInput14"
                                name="username"
                                defaultValue={editData.username}
                                onChange={handleProfileChange}
                              ></input>
                            </div>
                          )}
                        </div>
                        <div class="col-md-6 mt-3">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput15"
                              class="editLable form-label mb-0"
                            >
                              Contact Number
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput15"
                              name="contactnumber"
                              defaultValue={editData.contactnumber}
                              onChange={handleProfileChange}
                            ></input>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput16"
                              class="editLable form-label mb-0"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput16"
                              disabled
                              defaultValue={editData.email}
                            ></input>
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput17"
                              class="editLable form-label mb-0"
                            >
                              Date Of Birth
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput17"
                              name="dob"
                              defaultValue={editData.dob}
                              onChange={handleProfileChange}
                            ></input>
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput18"
                              class="editLable form-label mb-0"
                            >
                              Country
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput18"
                              name="country"
                              defaultValue={editData.country}
                              onChange={handleProfileChange}
                            ></input>
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput19"
                              class="editLable form-label mb-0"
                            >
                              City
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput19"
                              name="city"
                              defaultValue={editData.city}
                              onChange={handleProfileChange}
                            ></input>
                          </div>
                        </div>

                        <div class="container">
                          <div class="row">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col editButton">
                              <button
                                type="button"
                                onClick={handleUpdate}
                                class="btn btn-success"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<----------------------------------------Reset your password Accordian--------------------------->*/}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      value={userData[0]._id}
                      id="idps"
                      onClick={passwordClick}
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div class="container">
                        <div class="AccordianRow row mt-3">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <p className="accordianButton">
                              Reset Your password
                            </p>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col">
                            <div class="mb-3 mt-3">
                              <label
                                for="exampleFormControlInput11"
                                class="passwordLable form-label mb-0"
                              >
                                Enter Old Password
                              </label>
                              <input
                                type="password"
                                class="form-control mb-0 "
                                id="exampleFormControlInput11"
                                name="password"
                                onChange={handlePasswordChange}
                              ></input>
                              <p className="error mt-0">{error.password}</p>
                              <label
                                for="exampleFormControlInput12"
                                class="passwordLable form-label mb-0"
                              >
                                Create New Password
                              </label>
                              <input
                                type="password"
                                class="form-control mb-0"
                                id="exampleFormControlInput12"
                                name="newpassword"
                                onChange={handlePasswordChange}
                              ></input>
                              <p className="error mt-0">{error.newpassword}</p>
                              <label
                                for="exampleFormControlInput13"
                                class="passwordLable form-label mb-0"
                              >
                                Confirm New Password
                              </label>
                              <input
                                type="password"
                                class="form-control mb-0"
                                id="exampleFormControlInput13"
                                name="confnewpassword"
                                onChange={handlePasswordChange}
                              ></input>
                              <p className="error mt-0">
                                {error.confnewpassword}
                              </p>
                              <p className="error mt-0">{error.compare}</p>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                          <div class="container">
                            <div class="row">
                              <div class="col"></div>
                              <div class="col"></div>
                              <div class="col editButton">
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={resetPassClick}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <----------------------------------Log Out Accordian----------------------------------------------------------> */}
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        onClick={() => nevigate("/")}
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#"
                        aria-expanded="false"
                        aria-controls=""
                      >
                        <div class="container">
                          <div class="AccordianRow row mt-3">
                            <div class="col-md-3"></div>
                            <div
                              class="col-md-6"
                              id={userData[0].refrenceId}
                              onClick={handleLogOut}
                            >
                              <p className="accordianButton">Log Out</p>
                            </div>
                            <div class="col-md-3"></div>
                          </div>
                        </div>
                      </button>
                    </h2>
                  </div>
                  {/* <--------------------------------------------------Delete Account Accordian-------------------------------------------> */}
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        onClick={() =>
                          alert("I confirm i want delete my account !")
                        }
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <div class="container">
                          <div class="AccordianRow row mt-3">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                              <p className="accordianButton">
                                Delete Your Account
                              </p>
                            </div>
                            <div class="col-md-3"></div>
                          </div>
                        </div>
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
