import React, { useState, useEffect } from "react";
import "./Scroll.css";
import { BiLike } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa";
import FooterPage from "../../LandingPage/Footer/Footer";
import UserNavPage from "../UserNavBar/userNav";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../BaseUrl";
import { BsPersonBoundingBox } from "react-icons/bs";

import io from "socket.io-client";
const socket = io.connect(`${baseUrl}`);

export default function ScrollPage() {
  const nevigate = useNavigate();
  const location = useLocation();

  const [allUserData, setAllUserData] = useState([]);
  const [loginUserData, setLoginUserData] = useState([{}]);
  const [userId, setUserId] = useState(null);

  const useremail = location.state;

  useEffect(() => {
    if (socket) {
      socket.on("user-status", (data) => {
        setAllUserData(data);
      });
      return () => {
        socket.off("user-status");
      };
    }
  }, []);

  const getAllUserDataApi = () => {
    axios.get(`${baseUrl}/api/getalluser`).then((res) => {
      setAllUserData(res.data);
    });
  };
  const getLoginUserDataApi = () => {
    axios
      .get(`${baseUrl}/api/getuserdata/${useremail}`)
      .then((res) => {
        setLoginUserData(res.data);
        const newUserId = res.data[0]._id;
        setUserId(newUserId);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };
  useEffect(() => {
    getAllUserDataApi();
    getLoginUserDataApi();
  }, []);

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dob.getFullYear();
    const currentMonth = currentDate.getMonth();
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  }

  return (
    <>
      <UserNavPage useremail={useremail} loginUserData={loginUserData} />
      <div class="mainScroll container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10 mb-3">
            <div class="scrollCard card">
              <div class="card-body">
                <div class="container mt-5 mb-3">
                  <div className="row">
                    {allUserData?.map((row) => {
                      return (
                        <div key={row._id} className="col-md-4 mb-3">
                          <div className="userImageCard card">
                            {row.photo === undefined ? (
                              <div className="userImageContainer">
                                <div
                                  className={`userIcon ${
                                    row.isOnline === "Online" ? "Online1" : ""
                                  }`}
                                >
                                  <BsPersonBoundingBox
                                    className="userProfileIcon"
                                    onClick={() => {
                                      nevigate("/profile", {
                                        state: [
                                          row.email,
                                          useremail,
                                          loginUserData,
                                        ],
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="userImageContainer">
                                <img
                                  className={`userImage ${
                                    row.isOnline === "Online" ? "Online" : ""
                                  }`}
                                  alt="..."
                                  onClick={() => {
                                    nevigate("/profile", {
                                      state: [
                                        row.email,
                                        useremail,
                                        loginUserData,
                                      ],
                                    });
                                  }}
                                  src={`${baseUrl}/uploads/${row.photo}`}
                                />
                              </div>
                            )}
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <div className="likeIcon">
                                    {/* <BiLike id={row.refrenceId} onClick={handleLike} /> */}
                                  </div>
                                </div>
                                <div className="col-md-6"></div>
                              </div>
                            </div>
                            <hr className="mb-0" />
                            <div className="card-body">
                              <div className="memberName">
                                <p className="card-text">{row.username}</p>
                              </div>
                              <div className="memberAge">
                                <p className="card-text">
                                  {calculateAge(row.dob)} .{row.country}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
      {/* <div className="footerFixed"> */}
      <FooterPage />
      {/* </div> */}
    </>
  );
}
