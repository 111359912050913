import React, { useState, useEffect, useRef } from "react";
import "./Chat.css";
import UserNavPage from "../UserNavBar/userNav";
import { useLocation } from "react-router-dom";
import { FcVideoCall } from "react-icons/fc";
import baseUrl from "../../../BaseUrl";
import axios from "axios";
import io from "socket.io-client";
export const socket = io.connect(`${baseUrl}`);
export default function ChatingPage() {
  // const [loginUserData, setLoginUserData] = useState([{}]);
  const [getMessage, setGetMessage] = useState([]);
  const [message, setMessage] = useState("");
  const [matchedMessage, setMatchedMessage] = useState();
  const [userMessage, setUserMessage] = useState();
  const [messageUser, setMessageUser] = useState();
  const [allFriends, setAllFriends] = useState([]);
  const [chatProfile, setChatProfile] = useState("");
  const [recieveMessages, setRecieveMessages] = useState([]);
  const [recieveAllUserMessage, setRecieveAllUserMessages] = useState([]);
  const [messageRoom, setMessageRoom] = useState("");
  const [disabledCards, setDisabledCards] = useState(null);
  const [error, setError] = useState({});
  const [activeFriend, setActiveFriend] = useState(null);

  const location = useLocation();
  const useremail = location.state[0];
  const loginUserData = location.state[1];

  const getUserDataApi = () => {
    axios.get(`${baseUrl}/api/getuserdata/${useremail}`).then((res) => {
      // setLoginUserData(res.data);
      setMessageRoom({
        ...messageRoom,
        senderRefrenceId: res.data[0].refrenceId,
      });
      setMatchedMessage({
        ...matchedMessage,
        recieverRefrenceId: res.data[0].refrenceId,
      });
    });
  };
  useEffect(() => {
    getUserDataApi();
  }, []);

  const refrenceId = loginUserData[0].refrenceId;
  const getmessageApi = () => {
    axios.get(`${baseUrl}/api/getmessage/${refrenceId}`).then((res) => {
      setGetMessage(res.data);
    });
  };

  const recieverRefrenceId = loginUserData[0]?.refrenceId;
  const getFriends = () => {
    axios.get(`${baseUrl}/api/getfriends/${recieverRefrenceId}`).then((res) => {
      const { success, message } = res.data;
      if (success === true) {
        setAllFriends(message);
      } else {
        alert(message);
      }
    });
  };
  useEffect(() => {
    getmessageApi();
    getFriends();
  }, [loginUserData]);

  const messageChange = (ref, e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const messageValidate = (value) => {
    let error = {};
    if (!value.message) {
      console.log("you cant sent message");
    } else {
      messageSendApi();
    }
  };

  console.log("All Frineds", allFriends);
  const senderRefrenceId = loginUserData[0].refrenceId;
  const handleCardClick = (friendRefId) => {
    setActiveFriend((prevFriend) =>
      prevFriend === friendRefId ? null : friendRefId
    );
  };
  const messageJoinRoom = (info) => {
    if (disabledCards !== info.refrenceId) {
      setMessageRoom({ ...messageRoom, recieverRefrenceId: info.refrenceId });
      setChatProfile(info);

      if (socket) {
        socket.emit("messagejoin-room", {
          senderRefrenceId: messageRoom.senderRefrenceId,
          recieverRefrenceId: info.refrenceId,
        });
      }
      setDisabledCards(info.refrenceId);
    }
    socket.emit("mark-all-messages-as-read", {
      senderRefrenceId: senderRefrenceId,
      recieverRefrenceId: info.refrenceId,
    });
  };
  const messageSendApi = (value) => {
    socket.emit("send-message", { message, messageRoom });
    setTimeout(() => {
      socket.emit("send-Ref", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
      socket.emit("send-chstnotify", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
    }, 1000);
    let inputElements = document.querySelectorAll(".chatInput");
    inputElements.forEach((input) => {
      input.value = "";
    });
  };

  const messageSend = () => {
    messageValidate(message);
  };
  useEffect(() => {
    if (socket) {
      socket.on("recieve-message", (data) => {
        setRecieveMessages((prevData) => [data]);
      });

      return () => {
        socket.off("recieve-message");
      };
    }
  }, [socket]);

  useEffect(() => {
    socket.emit("send-Ref", { recieverRefrenceId: recieverRefrenceId });
    socket.on("recievealluser-message", (data) => {
      setRecieveAllUserMessages((prevData) => [data]);
    });

    return () => {
      socket.off("recievealluser-message");
    };
  }, []);

  console.log("recieveMessage", recieveMessages);

  console.log("SetRecieveMessage", recieveAllUserMessage);

  const getLastMessage = (friendRefId) => {
    let lastMessageData = {
      message: null,
      timestamp: null,
    };

    recieveAllUserMessage.forEach((data) => {
      const friendMessages = data.messages.filter(
        (item) => item.sender === friendRefId
      );
      if (friendMessages.length > 0) {
        const lastFriendMessage = friendMessages[friendMessages.length - 1];
        lastMessageData.message = lastFriendMessage.message;
        lastMessageData.timestamp = lastFriendMessage.timestamp;
      }
    });

    return lastMessageData;
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  const formatTime = (timestamp) => {
    const currentDate = new Date();
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayDiff = (currentDate - date) / (1000 * 60 * 60 * 24);

    if (dayDiff < 1) {
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
    } else if (dayDiff >= 1 && dayDiff < 2) {
      return "Yesterday";
    }
  };

  return (
    <>
      <UserNavPage useremail={useremail} loginUserData={loginUserData} />
      <div className="chatContainer container">
        <div className="chatRow row">
          <div className="col-md-1"></div>
          <div className="col mb-5">
            {/* <h3 className="">Messages</h3> */}
            <div className="mainChatCard card">
              <div className="row">
                <div className="messageBoxCol col-md-4">
                  <nav className="messageBoxNav navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                      <p className="mb-0 navbar-brand">Messaging</p>
                    </div>
                  </nav>
                  <div></div>
                  {Array.isArray(allFriends) &&
                    allFriends
                      .sort((a, b) => {
                        const aTimestamp = getLastMessage(
                          a.friendInfo._id
                        ).timestamp;
                        const bTimestamp = getLastMessage(
                          b.friendInfo._id
                        ).timestamp;
                        return new Date(bTimestamp) - new Date(aTimestamp);
                      })
                      .map((row) => (
                        <div
                          className={`chatCard card ${
                            activeFriend === row.friendReferenceId
                              ? "active"
                              : ""
                          }`}
                          key={row.friendReferenceId}
                          onClick={() => handleCardClick(row.friendReferenceId)}
                        >
                          <div className="container">
                            <div className="col">
                              <div
                                className="card-body"
                                onClick={() => messageJoinRoom(row.friendInfo)}
                              >
                                <div class="row">
                                  <div class="col-md-3">
                                    {row.friendInfo.photo === undefined ? (
                                      <div
                                        className={`senderprofileMess1 ${
                                          row.friendInfo.isOnline === "Online"
                                            ? "live1"
                                            : ""
                                        }`}
                                      >
                                        NA
                                      </div>
                                    ) : (
                                      <img
                                        className={`senderprofileMess2 ${
                                          row.friendInfo.isOnline === "Online"
                                            ? "live2"
                                            : ""
                                        }`}
                                        src={`${baseUrl}/uploads/${row.friendInfo.photo}`}
                                      ></img>
                                    )}
                                  </div>
                                  <div class="col">
                                    <div class="container">
                                      <div class="row">
                                        <div class="col">
                                          <p className="chatUserName">
                                            {row.friendInfo.username.substring(
                                              0,
                                              6
                                            )}
                                          </p>
                                        </div>
                                        <div class="col userChatTime">
                                          <p className="timestamp">
                                            {formatTime(
                                              getLastMessage(row.friendInfo._id)
                                                .timestamp
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="lastMessage">
                                      {
                                        getLastMessage(row.friendInfo._id)
                                          .message
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                <br></br>
                <div className="chatBoxCol col-md-8">
                  <div className="card">
                    {chatProfile === "" ? (
                      <div className="disableChatCard card">
                        <div className="card-body">
                          <div class="container">
                            <div class="chatblankrow row">
                              <div class="col"></div>
                              <div class="col-md-10">
                                <div class="chatblankcard card">
                                  <div class="card-body">
                                    <div class="container">
                                      <div class="chatblanksmallrow row">
                                        <div class="col"></div>
                                        <div class="col-md-10">
                                          <div className="chatblankicon">
                                            <div className="chatIconImg"></div>
                                          </div>
                                          <div className="chatblankTitle">
                                            <h5>Dating Meet Chat</h5>
                                          </div>
                                          <div>
                                            <p>
                                              Send and receive message without
                                              keeping your phone online. Are you
                                              ready to take the next step in
                                              your dating experience? Join us
                                              and explore the world of offline
                                              messaging today! Your
                                              conversations, your time – always
                                              connected, even when you're
                                              offline.{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <nav className="chatBoxNav navbar navbar-expand-lg navbar-light bg-light">
                          <div className="container-fluid">
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <p className="navbar-brand mb-0 ">
                                    {chatProfile.username}{" "}
                                    <span class="badge bg-success">
                                      {chatProfile.isOnline}
                                    </span>
                                  </p>
                                </div>
                                <div className="col">
                                  <div className="videoIconContainer navbar-brand mb-0">
                                    <FcVideoCall className="videoIcon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </nav>
                        <div className="card">
                          <div className="card-body">
                            <div className="container">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="profileChatImage">
                                    {chatProfile.photo === undefined ? (
                                      <div className="chatimageStyle1">NA</div>
                                    ) : (
                                      <img
                                        className="chatimageStyle"
                                        src={`${baseUrl}/uploads/${chatProfile.photo}`}
                                      ></img>
                                    )}
                                    <p className="ChatPara">
                                      {chatProfile.username}
                                    </p>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                          <div className="chatingCard card">
                            <div className="card-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12 col-sm-12">
                                    <div className="message-container">
                                      <div className="message-list">
                                        {recieveMessages.map(
                                          (data, dataIndex) => (
                                            <div key={dataIndex}>
                                              {data.messages.map(
                                                (message, messageIndex) => (
                                                  <div
                                                    key={messageIndex}
                                                    className={
                                                      message.sender ===
                                                      loginUserData[0]._id
                                                        ? "sent mb-3"
                                                        : "received mb-3"
                                                    }
                                                  >
                                                    <p className="messageStyle mb-0">
                                                      {message.message}
                                                    </p>
                                                    <span className="timeStyle">
                                                      {formatTimestamp(
                                                        message.timestamp
                                                      )}
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col">
                                    <div className="mb-3">
                                      <textarea
                                        className="chatInput form-control"
                                        name="message"
                                        onChange={(e) =>
                                          messageChange(chatProfile, e)
                                        }
                                        placeholder="write a message"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chatButton">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={messageSend}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
}
