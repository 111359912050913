import MainPage from "./Component/LandingPage/LandingPange";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollPage from "./Component/UserPannel/UserScroll/Scroll";
import ContactUsPage from "./Component/LandingPage/Contact/ContactUs";
import AboutUsPage from "./Component/LandingPage/About/AboutUs";
import ChatingPage from "./Component/UserPannel/Chating/Chat";
import ProfilePage from "./Component/UserPannel/Profile/Profiles";
import NotificationPage from "./Component/UserPannel/Notification/Notification";
import FrienRequestPage from "./Component/Friends/FriendRequest";
import AdminNavigationBar from "./AddminPannel/NevigationBar";
import AdminLoginPage from "./AdminLogin/AdminLogin";

function App() {
  const token = localStorage.getItem("token");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          {token && <Route path="/userpannel" element={<ScrollPage />} />}
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/chat" element={<ChatingPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/friendrequest" element={<FrienRequestPage />} />
          <Route path="/adminpannel" element={<AdminNavigationBar />} />
          <Route path="/adminlogin" element={<AdminLoginPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
