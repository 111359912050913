import React from "react";

export default function AboutUsPage(){


    return(
        <>
        <div>I am About us page</div>
        
        </>
    )
}