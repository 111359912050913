import React, { useEffect, useState } from "react";
import UserNavPage from "../UserPannel/UserNavBar/userNav";
import "./FriendRequest.css";
import baseUrl from "../../BaseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BsThreeDots } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import io from "socket.io-client";
export const socket = io.connect(`${baseUrl}`);
export default function FrienRequestPage() {
  const location = useLocation();
  const useremail = location.state[0];
  const loginUserData = location.state[1];
  const nevigate = useNavigate();

  const [allFriendRequest, setAllFriendRequest] = useState([]);
  const [confirmedFriend, setConfirmedFriend] = useState([]);
  const [showFriends, setShowFriends] = useState(false);
  const [message, setMessage] = useState("");
  const [messageRoom, setMessageRoom] = useState("");

  const recieverRefrenceId = loginUserData[0]?.refrenceId;

  const getFriendRequest = () => {
    console.log(recieverRefrenceId);
    axios
      .get(`${baseUrl}/api/getfriendrequest/${recieverRefrenceId}`)
      .then((res) => {
        const { success, data } = res.data;
        if (success === true) {
          setAllFriendRequest(data.filter((item) => item.status === "pending"));
        } else {
          setAllFriendRequest(false);
        }
      });
  };
  const getFriends = () => {
    axios.get(`${baseUrl}/api/getfriends/${recieverRefrenceId}`).then((res) => {
      const { success, message } = res.data;
      if (success === true) {
        setConfirmedFriend(message);
      } else {
        setConfirmedFriend(false);
      }
    });
  };

  useEffect(() => {
    getFriendRequest();
  }, []);

  useEffect(() => {
    getFriends();
  }, [loginUserData]);

  console.log("AllFriendRequest", allFriendRequest);

  const senderRefrenceId = loginUserData[0].refrenceId;
  const messageClick = (e) => {
    const recieverRefrenceId = e.target.getAttribute("id");
    setMessageRoom({
      ...messageRoom,
      senderRefrenceId: senderRefrenceId,
      recieverRefrenceId: recieverRefrenceId,
    });

    if (socket) {
      socket.emit("messagejoin-room", {
        senderRefrenceId: senderRefrenceId,
        recieverRefrenceId: recieverRefrenceId,
      });
    }
  };
  const messageChange = (e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const messageSend = () => {
    socket.emit("send-message", { message, messageRoom });
    setTimeout(() => {
      socket.emit("send-Ref", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
      socket.emit("send-chstnotify", {
        recieverRefrenceId: messageRoom.recieverRefrenceId,
      });
    }, 1000);
    let inputElements = document.querySelectorAll(".chatInput1");
    inputElements.forEach((input) => {
      input.value = "";
    });
  };

  const FriendRequestAccept = (e) => {
    const requestId = e.target.getAttribute("id");
    console.log(requestId);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${baseUrl}/api/acceptfriendrequest/${requestId}`)
          .then((res) => {
            const { message, success } = res.data;
            if (success) {
              Swal.fire({
                title: "Friend Request Accepted",
                text: "You clicked the button!",
                icon: "success",
              });

              window.location.reload();
            } else {
              alert(message);
            }
          });
      }
    });
  };
  const FrienRequestDelete = (e) => {
    const senderId = e.target.getAttribute("id");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/api/deletefriendrequest/${senderId}`)
          .then((res) => {
            const { message, success } = res.data;
            if (success) {
              Swal.fire({
                title: message,
                text: "You clicked the button!",
                icon: "success",
              });
              window.location.reload();
            } else {
              alert(message);
            }
          });
      }
    });
  };
  // const pendingRequest = allFriendRequest.filter((item) => item.status === 'pending');

  console.log(
    "Friend Request.......................................................",
    allFriendRequest
  );

  const handleUnfriend = (e) => {
    const id = e.target.getAttribute("id");
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unfriend it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${baseUrl}/api/unfriend/${id}`).then((res) => {
          const { message, success } = res.data;
          if (success) {
            Swal({
              title: message,
              text: "You clicked the button!",
              icon: "success",
            });
          } else {
            alert(message);
          }
        });
        window.location.reload();
      }
    });
  };

  const formatTime = (timestamp) => {
    const currentDate = new Date();
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayDiff = (currentDate - date) / (1000 * 60 * 60 * 24);

    if (dayDiff < 1) {
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
    } else if (dayDiff >= 1 && dayDiff < 2) {
      return "Yesterday";
    }
  };

  console.log("ConFRequestData", confirmedFriend);

  return (
    <>
      <UserNavPage useremail={useremail} loginUserData={loginUserData} />
      <div class="friendContainer container">
        <div class="row">
          <div class="col"></div>
          <div class="col-md-7">
            <div class="mainFriendCard card">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <button
                      className="showFriends btn"
                      onClick={() => {
                        setShowFriends(true);
                      }}
                    >
                      Show All Friends: {confirmedFriend.length}{" "}
                    </button>
                  </div>
                  <div class="col"></div>
                  <div class="col">
                    <p>Panding Request: {allFriendRequest.length}</p>
                  </div>
                </div>
              </div>
              {allFriendRequest.length === 0 ? (
                <div class=" card">
                  <div class="card-body">
                    <div class="card">
                      <div class="nofriendRequest card-body">
                        <p>You don't have any friend request!</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="card-body">
                  {allFriendRequest
                    .sort(
                      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                    )
                    ?.map((row) => {
                      return (
                        <div key={row.id} class="card mb-3">
                          <div class="friendRequestBody card-body">
                            <div class="container">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-md-3 col-sm-3 col-xs-3">
                                        {row.sender.photo === undefined ? (
                                          <div class="senderprofileStyle">
                                            NA
                                          </div>
                                        ) : (
                                          <img
                                            className="senderProfile"
                                            src={`${baseUrl}/uploads/${row.sender.photo}`}
                                          ></img>
                                        )}
                                      </div>
                                      <div class="col">
                                        <p className="RequesterName mb-0">
                                          {row.sender.username}
                                        </p>
                                        <p>Sent a Friend Request</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="container">
                                    <div class="row">
                                      <div class="col">
                                        <button
                                          type="button"
                                          class="btn btn-danger"
                                          id={row._id}
                                          onClick={FrienRequestDelete}
                                        >
                                          Ignore
                                        </button>
                                      </div>
                                      <div class="col">
                                        <button
                                          type="button"
                                          class="btn btn-success"
                                          id={row._id}
                                          onClick={FriendRequestAccept}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <p className="timestamp">
                                    {formatTime(row.timestamp)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            {showFriends === true ? (
              <div class="card">
                <div class="card-body">
                  <p>{confirmedFriend.length} Friends</p>
                </div>
                {confirmedFriend?.map((row) => {
                  return (
                    <div key={row.id} class="card confirmFriendCard">
                      <div class="card-body">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-3 col-sm-3 col-xs-3">
                                    {row.friendInfo.photo === undefined ? (
                                      <div class="senderprofileStyle">NA</div>
                                    ) : (
                                      <img
                                        className="senderProfile"
                                        src={`${baseUrl}/uploads/${row.friendInfo.photo}`}
                                      ></img>
                                    )}
                                  </div>
                                  <div class="col">
                                    <button
                                      className="RequesterName btn mb-0"
                                      onClick={() =>
                                        nevigate("/profile", {
                                          state: [
                                            row.friendInfo.email,
                                            useremail,
                                            loginUserData,
                                          ],
                                        })
                                      }
                                    >
                                      {row.friendInfo.username}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                              <div class="container">
                                <div class="row">
                                  <div class="col">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary"
                                      data-bs-toggle="modal"
                                      id={row.friendInfo.refrenceId}
                                      onClick={messageClick}
                                      data-bs-target="#staticBackdrop"
                                    >
                                      Messages
                                    </button>
                                  </div>
                                  <div class="col">
                                    <button
                                      className="removeConnection btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenu2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <BsThreeDots className="threeDotsIcon" />
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          type="button"
                                          id={row.requestId}
                                          onClick={handleUnfriend}
                                        >
                                          <RiDeleteBin6Line /> Remove Friend
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div
        className="mt-5 modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="messageModal modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="messageHead modal-title" id="staticBackdropLabel">
                Send a message
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="messagelable form-label"
                >
                  Your Message
                </label>
                <textarea
                  class="chatInput1 form-control "
                  name="message"
                  onChange={messageChange}
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={messageSend}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
