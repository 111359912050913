import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import BottumPage from "./Bottum/Bottum";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FooterPage from "./Footer/Footer";
import { socket } from "../UserPannel/Chating/Chat";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import baseUrl from "../../BaseUrl";

export default function MainPage() {
  const nevigate = useNavigate();
  const [error, setError] = useState({});
  const [signUpData, setSignUpData] = useState("");
  const [loginData, setLoginData] = useState("");
  const [loginPage, setLoginPage] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState();

  const Email = loginData.email;

  const handleLoginPage = () => {
    setLoginPage(true);
  };
  const handleSignUpPage = () => {
    setLoginPage(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value });
  };
  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleValidateSignUp = (value) => {
    let error = {};
    const regexForEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    const regixForPhone =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const selectedDate = new Date(value.dob);
    const minimumAgeDate = new Date("2005-07-07");
    if (selectedDate > minimumAgeDate) {
      error.age = "Age should be minimum 18";
    } else if (!value.username) {
      error.username = "username required";
    } else if (!regixForPhone.test(value.contactnumber)) {
      error.contactnumber = "phone [10-12] is required";
    } else if (!regexForEmail.test(value.email)) {
      error.email = "email is required @gmail formate.";
    } else if (!value.password) {
      error.password = "password required";
    } else if (!value.dob) {
      error.dob = "date of birth required";
    } else if (!value.city) {
      error.city = "city required";
    } else if (!value.country) {
      error.country = "country required";
    } else if (!value.gender) {
      error.gender = "gender required";
    } else if (!value.lookingfor) {
      error.lookingfor = "looking for required";
    } else {
      SignUpApi();
    }
    setError(error);
  };
  const handleValidateLogin = (value) => {
    let error = {};
    if (!value.email) {
      error.email1 = "email required";
    } else if (!value.password) {
      error.password1 = "password required";
    } else {
      LoginApi();
    }
    setError(error);
  };

  const handleClick = () => {
    handleValidateSignUp(signUpData);
  };
  const handleClickLogin = () => {
    handleValidateLogin(loginData);
  };
  const SignUpApi = () => {
    axios.post(`${baseUrl}/api/signup`, signUpData).then((res) => {
      const { message, success } = res.data;
      if (success === true) {
        alert(message);
        window.location.reload();
      } else {
        alert(message);
      }
    });
  };

  const LoginApi = () => {
    axios.post(`${baseUrl}/api/login`, loginData).then((res) => {
      const { message, success, token } = res.data;
      if (success) {
        alert(message);
        localStorage.setItem("token", token);
        socket.emit("login", loginData.email);
        setTimeout(() => {
          nevigate("/userpannel", { state: loginData.email });
          window.location.reload();
        }, 1000);
      } else {
        alert(message);
      }
    });
  };

  const handleGoogleLogin = async (credentialResponse) => {
    // const token = jwt_decode(credentialResponse.credential);
    const token = credentialResponse.credential;
    await axios.post(`${baseUrl}/google-auth`, { token }).then((res) => {
      const { token, message, email, success } = res.data;
      if (success) {
        socket.emit("login", email);
        localStorage.setItem("token", token);
        nevigate("/userpannel", { state: email });
        window.location.reload();
      } else {
        alert(message);
      }
    });
  };

  const buttonStyle = {
    width: "174px", // Adjust the width as needed
    fontSize: "10px", // Adjust the font size as needed
    height: "40px",
    paddingTop: "10px",
    textAlign: "start",
    paddingLeft: "16px",
    marginLeft: "14px",
  };

  return (
    <>
      <div className="NavHeader">
        <nav class="mainNav navbar ">
          <div class="container-fluid">
            <p class="navbar-brand">Dating Meet</p>
            <div class="row">
              <div class="col">
                <p class="navPara navbar-brand">Already have an account ?</p>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="navButton btn btn-secondary"
                  onClick={handleLoginPage}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="mainStyle">
        <div class="container">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
              {loginPage === false ? (
                <div class="signUpCard card">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Register Now</h6>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="lableStyle form-label"
                      >
                        User Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        name="username"
                        placeholder="Username"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.username}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput2"
                        class="lableStyle form-label"
                      >
                        Contact Number
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleFormControlInput2"
                        name="contactnumber"
                        placeholder="Contact Number"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.contactnumber}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput3"
                        class="lableStyle form-label"
                      >
                        Your Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput3"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.email}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput4"
                        class="lableStyle form-label"
                      >
                        Your Password
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleFormControlInput4"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.password}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput5"
                        class="lableStyle form-label"
                      >
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        id="exampleFormControlInput5"
                        name="dob"
                        placeholder="Date of birth"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.dob}</p>
                      <p className="error">{error.age}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput6"
                        class="lableStyle form-label"
                      >
                        Country
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example6"
                        id="exampleFormControlInput6"
                        name="country"
                        onChange={handleChange}
                      >
                        <option selected value="">
                          Select your country
                        </option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antartica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegowina">
                          Bosnia and Herzegowina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo">
                          Congo, the Democratic Republic of the
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                        <option value="Croatia">Croatia (Hrvatska)</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="France Metropolitan">
                          France, Metropolitan
                        </option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard and McDonald Islands">
                          Heard and Mc Donald Islands
                        </option>
                        <option value="Holy See">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran">Iran (Islamic Republic of)</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Democratic People's Republic of Korea">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint LUCIA">Saint LUCIA</option>
                        <option value="Saint Vincent">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">
                          Slovakia (Slovak Republic)
                        </option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="Span">Spain</option>
                        <option value="SriLanka">Sri Lanka</option>
                        <option value="St. Helena">St. Helena</option>
                        <option value="St. Pierre and Miguelon">
                          St. Pierre and Miquelon
                        </option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard">
                          Svalbard and Jan Mayen Islands
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syrian Arab Republic</option>
                        <option value="Taiwan">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Viet Nam</option>
                        <option value="Virgin Islands (British)">
                          Virgin Islands (British)
                        </option>
                        <option value="Virgin Islands (U.S)">
                          Virgin Islands (U.S.)
                        </option>
                        <option value="Wallis and Futana Islands">
                          Wallis and Futuna Islands
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      <p className="error">{error.country}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput0"
                        class="lableStyle form-label"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput0"
                        name="city"
                        placeholder="Where do you live"
                        onChange={handleChange}
                      ></input>
                      <p className="error">{error.city}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput7"
                        class="lableStyle form-label"
                      >
                        Gender
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        id="exampleFormControlInput7"
                        name="gender"
                        onChange={handleChange}
                      >
                        <option value="Null" selected>
                          I am
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Lesibian">Lesibian</option>
                        <option value="Gay">Gay</option>
                      </select>
                      <p className="error">{error.gender}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput8"
                        class="lableStyle form-label"
                      >
                        Looking For
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        id="exampleFormControlInput8"
                        name="lookingfor"
                        onChange={handleChange}
                      >
                        <option selected value="Null">
                          Looking For
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Lesibian">Lesibian</option>
                        <option value="Gay">Gay</option>
                      </select>
                      <p className="error">{error.lookingfor}</p>
                    </div>
                    <div class="mb-3">
                      <button
                        type="button"
                        class="signUpBtn btn btn-dark"
                        onClick={handleClick}
                      >
                        Create your account
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="signUpCard card">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Login Now</h6>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput9"
                        class="lableStyle form-label"
                      >
                        Your Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput9"
                        name="email"
                        placeholder="Email"
                        onChange={handleChangeLogin}
                      ></input>
                      <p className="error">{error.email1}</p>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput"
                        class="lableStyle form-label"
                      >
                        Your Password
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleFormControlInput"
                        name="password"
                        placeholder="Password"
                        onChange={handleChangeLogin}
                      ></input>
                      <p className="error">{error.password1}</p>
                    </div>
                    <div class="mb-3">
                      <button
                        type="button"
                        class="signUpBtn btn btn-dark"
                        onClick={handleClickLogin}
                      >
                        Login
                      </button>
                    </div>
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={handleSignUpPage}
                    >
                      Don't have an account ?
                    </button>

                    <div class="container">
                      <div class="row">
                        <div class="col-md-6">
                          <GoogleOAuthProvider clientId="786535604978-m0au5o0901mqd2sld4945bigrk88r5aq.apps.googleusercontent.com">
                            <GoogleLogin
                              onSuccess={handleGoogleLogin}
                              onError={() => {
                                console.log("Login Failed");
                              }}
                            />
                          </GoogleOAuthProvider>
                          ;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottumPage />
      <FooterPage />
    </>
  );
}
