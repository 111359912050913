import React from "react";
import './NevigationBar.css'
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import SideBarPage from "./Sidebar";

export default function AdminNavigationBar({ chooseMessage }) {

  const nevigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(true);
  
  const handleClick= () =>{
    
    setShowSideBar(!showSideBar);
    // chooseMessage(!showSideBar);

  }
  return (
    <>
      <div className="NavStyle">
        <nav class="navbar navbar-expand-lg">

          <div class="container-fluid">
            <a class="navbar-brand" href="#">Navbar</a>
            <div onClick={handleClick} className={`${showSideBar ? 'menu' : 'closedMenu'}`} >
              <AiOutlineMenu ></AiOutlineMenu>
            </div>
            <><div class="profileStyle  dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">NA</div>
              <ul class="ProfileUl dropdown-menu">
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="/">Log Out</a></li>
              </ul></>
          </div>
        </nav>
        <SideBarPage showSideBar={showSideBar} />
        
      </div>
     
    </>
  )
}