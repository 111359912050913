import React from "react";
import './Footer.css'
import { useNavigate } from "react-router-dom";

export default function FooterPage() {

    const nevigate = useNavigate();

    return (
        <>
            <nav class="footerStyle navbar navbar-light bg-light">
                <div class="container-fluid">
                    <div class="container mb-3">
                        <div class="row">
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-6">

                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                          <div  className="footerPara" onClick={()=>nevigate('/contactus')}>
                                            <p>Contact Us</p>
                                          </div>
                                        </div>
                                        <div class="col">
                                        <div className="footerPara"onClick={()=>nevigate('/aboutus')}>
                                            <p>About Us</p>
                                          </div>
                                        </div>
                                        <div class="col">
                                        <div className="footerPara">
                                            <p>Safety</p>
                                          </div>
                                        </div>
                                        <div class="col">
                                        <div className="footerPara">
                                            <p>Privacy policy</p>
                                          </div>
                                        </div>
                                        <div class="col">
                                        <div className="footerPara">
                                            <p>Terms of use</p>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">

                            </div>
                        </div>
                    </div>
                </div>
            </nav>


        </>
    )
}