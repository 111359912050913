import React from "react";
import './Bottum.css'
import { BiLogInCircle } from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsChatHeart } from "react-icons/bs";
export default function BottumPage() {

    return (
        <>
            <div class="container mt-5 mb-5">
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col">
                        <div class="container mt-5 mb-5">
                            <div className="middlePara mb-5">
                                <p>
                                    How it Works?
                                </p>
                                <p>As Simple As That!</p>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-4">
                                    <div class="bottumCard card" >
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="cardIcon">
                                                        <BiLogInCircle />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                </div>
                                                <div class="col-md-6">
                                                    <div className="cardContent mt-3">
                                                        <p>Join Us</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                </div>
                                                <hr></hr>
                                                <div className="cardBottumPara">
                                                <p>Join Us for Free and Make New Friends & Relations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-4">
                                    <div class="bottumCard card">
                                    <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="cardIcon">
                                                        <AiOutlineUsergroupAdd />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1">
                                                </div>
                                                <div class="col-md-10">
                                                    <div className="cardContent mt-3">
                                                        <p>Add Friends</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                </div>
                                                <hr></hr>
                                                <div className="cardBottumPara">
                                                <p>Find your Best Matches and Add Them as Friend.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-4">
                                    <div class="bottumCard card">
                                    <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="cardIcon">
                                                        <BsChatHeart />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1">
                                                </div>
                                                <div class="col-md-10">
                                                    <div className="cardContent mt-3">
                                                        <p>Start a Chat</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                </div>
                                                <hr></hr>
                                                <div className="cardBottumPara">
                                                <p>Once your match accepts you, you can start an awesome conversation.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>
            </div>


        </>
    )
}