import React from "react";
import './ContactUs.css'

export default function ContactUsPage(){

    return(
        <>
        <div>I am Contact us page</div>
        </>
    )
}